var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"add-new-user-sidebar","visible":_vm.isAddNewUserSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"change":function (val) { return _vm.$emit('update:is-add-new-user-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Add Ops User ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"registerForm"},[_c('b-form',{staticClass:"auth-register-form mt-2 ml-2 mr-2"},[_c('b-form-group',{attrs:{"label":"Firstname","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-firstname","name":"register-firstname","state":errors.length > 0 ? false:null},model:{value:(_vm.newOpsObj.firstName),callback:function ($$v) {_vm.$set(_vm.newOpsObj, "firstName", $$v)},expression:"newOpsObj.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Lastname","label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":"Lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-lastname","name":"register-lastname","state":errors.length > 0 ? false:null},model:{value:(_vm.newOpsObj.lastName),callback:function ($$v) {_vm.$set(_vm.newOpsObj, "lastName", $$v)},expression:"newOpsObj.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.newOpsObj.email),callback:function ($$v) {_vm.$set(_vm.newOpsObj, "email", $$v)},expression:"newOpsObj.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Country","label-for":"register-country"}},[_c('country-select',{staticStyle:{"background-color":"white"},attrs:{"country":_vm.newOpsObj.country,"topCountry":"US","required":"","className":"form-control"},model:{value:(_vm.newOpsObj.country),callback:function ($$v) {_vm.$set(_vm.newOpsObj, "country", $$v)},expression:"newOpsObj.country"}})],1),_c('b-form-group',{attrs:{"label":"Phone number","label-for":"register-country"}},[_c('VuePhoneNumberInput',{attrs:{"fetch-country":true,"required":true},on:{"update":_vm.phoneUpdate},model:{value:(_vm.newOpsObj.phone),callback:function ($$v) {_vm.$set(_vm.newOpsObj, "phone", $$v)},expression:"newOpsObj.phone"}})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"register-password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('password',{attrs:{"toggle":true,"required":""},model:{value:(_vm.newOpsObj.password),callback:function ($$v) {_vm.$set(_vm.newOpsObj, "password", $$v)},expression:"newOpsObj.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('br')]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","size":"lg","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.saveOpsUser.apply(null, arguments)}}},[_vm._v(" Save ")]),_c('br'),_c('br'),(_vm.Opserror)?_c('span',{staticClass:"badge badge-danger"},[_vm._v(" "+_vm._s(_vm.Opserror)+" ")]):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }