<template>
<div style="margin-left:30px;margin-right:30px;">
  <h2><center>Ops Users</center></h2>
    <br/><br/>
  <OpsUserAddNew
    :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
    @update:is-add-new-user-sidebar-active = "closesideBar"
  />
  <b-card
    no-body
    class="mb-0"
  >
  <div class="m-2">
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
      <label>Show</label>
      <v-select
        v-model="perPage"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="perPageOptions"
        :clearable="false"
        class="per-page-selector d-inline-block mx-50"
      />
      <label>entries</label>

    </b-col>
    <b-col
      cols="12"
      md="6"
    >
    <div class="d-flex align-items-center justify-content-end">

      <b-button
        variant="primary"
        @click="isAddNewUserSidebarActive = true"
      >
        <span class="text-nowrap">Add OpsUser</span>
      </b-button>
    </div>
    </b-col>
    </b-row>
  </div>
  <b-table
    ref="refOpsUserListTable"
    class="position-relative"
    :items="allOpsUsers"
    responsive
    :fields="tableColumns"
    primary-key="id"
    show-empty
    empty-text="No matching records found"
  >
  <template #cell(firstName)="data">
    <div class="text-nowrap">
      {{ data.item.firstName }}
    </div>
  </template>
  <template #cell(lastName)="data">
    <div class="text-nowrap">
      {{ data.item.lastName }}
    </div>
  </template>
  <template #cell(email)="data">
    <div class="text-nowrap">
      {{ data.item.email }}
    </div>
  </template>
  <template #cell(phone)="data">
    <div class="text-nowrap">
      {{ data.item.phone }}
    </div>
  </template>
  <template #cell(country)="data">
    <div class="text-nowrap">
      {{ data.item.country }}
    </div>
  </template>
  <template #cell(actions)="data">
    <b-dropdown
      variant="link"
      no-caret
      :right="$store.state.appConfig.isRTL"
    >

      <template #button-content>
        <feather-icon
          icon="MoreVerticalIcon"
          size="16"
          class="align-middle text-body"
        />
      </template>
      <b-dropdown-item v-if="data.item.isblocked" @click="unblockOpsuser(data.item.id)">
        <feather-icon icon="EditIcon" />
        <span class="align-middle ml-50">UnBlock</span>
      </b-dropdown-item>
      <b-dropdown-item v-if="!data.item.isblocked" @click="blockOpsuser(data.item.id)">
        <feather-icon icon="EditIcon" />
        <span class="align-middle ml-50">Block</span>
      </b-dropdown-item>
      <b-dropdown-item @click="editUser(data.item)">
        <feather-icon icon="EditIcon" />
        <span class="align-middle ml-50">Edit</span>
      </b-dropdown-item>

      <b-dropdown-item @click="deleteOpsuser(data.item.id)">
        <feather-icon icon="TrashIcon" />
        <span class="align-middle ml-50">Delete</span>
      </b-dropdown-item>
    </b-dropdown>
  </template>
  </b-table>
  <div class="mx-2 mb-2">
    <b-row>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-start"
      >
        <span class="text-muted">Showing {{from }} to {{ to }} of {{ totalOpsUser }} entries</span>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex align-items-center justify-content-center justify-content-sm-end"
      >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalOpsUser"
        :per-page="perPage"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-col>
    </b-row>
  </div>
  <div class="m-2">
    <b-modal
      ref="shippingPrice"
      title="Edit Ops User"
      v-model="showOpsUserEdit"

    >
    <validation-observer ref="registerForm">
      <b-form class="auth-register-form mt-2 ml-2 mr-2">
        <!-- firstname -->
        <b-form-group label="Firstname" label-for="register-firstname">
          <validation-provider #default="{ errors }" name="firstName" rules="required">
            <b-form-input class="mb-1" id="register-firstname" v-model="editUserInfo.firstName" name="register-firstname"
              :state="errors.length > 0 ? false:null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- lastname -->
        <b-form-group  label="Lastname" label-for="register-lastname">
          <validation-provider #default="{ errors }" name="Lastname" rules="required">
            <b-form-input  class="mb-1" id="register-lastname" v-model="editUserInfo.lastName" name="register-lastname"
              :state="errors.length > 0 ? false:null" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- email -->
        <!-- <b-form-group label="Email" label-for="register-email">
          <validation-provider #default="{ errors }" name="Email" rules="required|email">
            <b-form-input id="register-email" v-model="editUserInfo.email" name="register-email"
              :state="errors.length > 0 ? false:null" placeholder="john@example.com" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->
        <b-form-group  label="Country" label-for="register-country">
          <country-select v-model="editUserInfo.country" :country="editUserInfo.country" topCountry="US" style="background-color:white;margin-bottom:10px;"
            required className="form-control" />
        </b-form-group>
        <b-form-group  label="Phone number" label-for="register-country">
          <VuePhoneNumberInput v-model="editUserInfo.phone" :fetch-country="true" @update="phoneUpdate"
            :required="true" style="margin-bottom:10px;"/>
        </b-form-group>
        <!-- <b-form-group label="Password" label-for="register-password">
          <validation-provider #default="{ errors }" name="Password" rules="required">
            <password v-model="editUserInfo.password" :toggle="true" required />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->


        <b-button  class="mt-2" variant="primary" size="lg" type="submit" @click.prevent="saveOpsUser">
          Save
        </b-button>
      </b-form>
    </validation-observer>
  </b-modal>
  </div>
  </b-card>

</div>

</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BPopover
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import OpsUserAddNew from './opsUserAddNew.vue'
import { mapMutations, mapActions, mapState } from "vuex";
import BCardCode from '@core/components/b-card-code';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import Ripple from 'vue-ripple-directive'
export default {
  name:"opsUsers",
  data(){
    return {
      perPage:10,
      currentPage : 1,
      perPageOptions : [10, 25, 50, 100],
      searchQuery:'',
      tableColumns :[
        { key: 'firstName'},
        { key: 'lastName'},
        { key: 'email'},
        { key: 'phone'},
        { key: 'country'},
        { key: 'actions' }
      ],
      skip:0,
      from:0,
      to:0,
      isAddNewUserSidebarActive:false,
      refOpsUserListTable:"opsUsers",
      showOpsUserEdit:false,
      editUserInfo:{
        firstName: '',
        lastName: '',
        phone: '',
        country: '',
        id:null
      },
      editUserId:null,

    }
  },
  directives: {
    Ripple
  },
  components: {
    OpsUserAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BPopover,
    vSelect,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput
  },
  methods:{
    closesideBar(val){
      console.log("sidebar value"+val);
    },
    ...mapActions({
      loadOpsUser:"userDetails/fetchAllOpsUser",
      blockOpsUser:"userDetails/blockOpsUser",
      unblockOpsUser:"userDetails/unblockOpsUser",
      deleteOpsUser:"userDetails/deleteOpsUser",
      updateOpsUser:"userDetails/updateOpsUser"
    }),
    reload(){
      this.loadOpsUser({skip:this.skip,limit:this.perPage})
    },
    unblockOpsuser(id){
      this.unblockOpsUser(id);
      this.reloadPage();
    },
    blockOpsuser(id){
      this.blockOpsUser(id);
      this.reloadPage();
    },
    deleteOpsuser(id){
      this.deleteOpsUser(id);
      this.reloadPage();
    },
    reloadPage(){
      if (this.currentPage == 1) {
        this.reload();
      }
      else{
        this.currentPage = 1;
      }
    },

    editUser(userInfo){
      console.log("edit userInfo"+JSON.stringify(userInfo))
      this.editUserInfo.firstName = userInfo.firstName;
      this.editUserInfo.lastName = userInfo.lastName;
      this.editUserInfo.phone = userInfo.phone;
      this.editUserInfo.country = userInfo.country;
      this.editUserInfo.id = userInfo.id;
      this.showOpsUserEdit = true;
    },
    phoneUpdate(event) {
      this.editUserInfo.phone = event.formattedNumber;
    },

    async saveOpsUser() {
      const comp = this;
      console.log("save ops user is called...")
      console.log("newOpsObj"+JSON.stringify(this.editUserInfo));
      var success = await this.$refs.registerForm.validate()
      console.log("success value"+JSON.stringify(success));
      if (success && comp.editUserInfo.country !== '' && comp.editUserInfo.phone !== '') {
        console.log("editing the user Info"+JSON.stringify(comp.editUserInfo))
        await comp.updateOpsUser(comp.editUserInfo);
        this.reloadPage();
        this.showOpsUserEdit = false;

        }

    },

  },
  mounted(){
    var query = {
      skip:0,
      limit:10
    }
    this.loadOpsUser(query)
  },
  computed:{
    ...mapState({
      allOpsUsers :(state) =>{
       return state.userDetails.allOpsUsers;
     },
     totalOpsUser: (state) =>{
       return state.userDetails.totalOpsUser;
     }
    })
  },
  watch:{
    currentPage:{
      handler(){
        this.skip = (this.currentPage - 1) * this.perPage
        this.reload()
      }
    },
    perPage:{
      handler(){
        this.skip = (this.currentPage - 1) * this.perPage
        this.reload()
      }
    },
    allOpsUsers:{
      handler(){
        const allOpsUsersLength = this.allOpsUsers.length;
        this.from = this.perPage * (this.currentPage - 1) + (allOpsUsersLength ? 1 : 0);
        this.to =  this.perPage * (this.currentPage - 1) + allOpsUsersLength;
      }
    }
  },

}

</script>
<style>
.modal .modal-footer {
    padding: 0;
    border: none;
}
.modal .modal-footer > .btn {
    /* margin-left: 0.75rem; */
    display: none;
}

</style>
