<template style="z-index:1000">
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"


  >
  <template #default="{ hide }">
    <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
      <h5 class="mb-0">
        Add Ops User
      </h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="hide"
      />
      </div>
      <validation-observer ref="registerForm">
        <b-form class="auth-register-form mt-2 ml-2 mr-2">
          <!-- firstname -->
          <b-form-group label="Firstname" label-for="register-username">
            <validation-provider #default="{ errors }" name="Username" rules="required">
              <b-form-input id="register-firstname" v-model="newOpsObj.firstName" name="register-firstname"
                :state="errors.length > 0 ? false:null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- lastname -->
          <b-form-group label="Lastname" label-for="register-username">
            <validation-provider #default="{ errors }" name="Lastname" rules="required">
              <b-form-input id="register-lastname" v-model="newOpsObj.lastName" name="register-lastname"
                :state="errors.length > 0 ? false:null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- email -->
          <b-form-group label="Email" label-for="register-email">
            <validation-provider #default="{ errors }" name="Email" rules="required|email">
              <b-form-input id="register-email" v-model="newOpsObj.email" name="register-email"
                :state="errors.length > 0 ? false:null" placeholder="john@example.com" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group label="Country" label-for="register-country">
            <country-select v-model="newOpsObj.country" :country="newOpsObj.country" topCountry="US" style="background-color:white"
              required className="form-control" />
          </b-form-group>
          <b-form-group label="Phone number" label-for="register-country">
            <VuePhoneNumberInput v-model="newOpsObj.phone" :fetch-country="true" @update="phoneUpdate"
              :required="true" />
          </b-form-group>
          <b-form-group label="Password" label-for="register-password">
            <validation-provider #default="{ errors }" name="Password" rules="required">
              <password v-model="newOpsObj.password" :toggle="true" required />
              <small class="text-danger">{{ errors[0] }}</small>
              <br/>
            </validation-provider>
          </b-form-group>


          <b-button variant="primary" size="lg" type="submit" @click.prevent="saveOpsUser">
            Save
          </b-button>
          <br/><br/>

          <span class="badge badge-danger" v-if="Opserror">
                  {{Opserror}}
                </span>
        </b-form>
      </validation-observer>


  </template>
  </b-sidebar>

</template>
<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { mapMutations, mapActions, mapState } from "vuex";
import Password from "vue-password-strength-meter";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    VuePhoneNumberInput,
    Password
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    }

  },
  data() {
    return {
      newOpsObj:{
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: '',
        password: "",
      }

    }
  },
  computed :{
    ...mapState({
      Opserror :(state) =>{

       return state.userDetails.errors;
     }
    })
  },
  methods :{
    ...mapActions({ createOpsUser: "userDetails/createOpsUser" }),
    async saveOpsUser() {
      const comp = this;
//  comp.$validator.errors.clear()

      console.log("save ops user is called...")
      console.log("newOpsObj"+JSON.stringify(this.newOpsObj));
      var success = await this.$refs.registerForm.validate()
      console.log("success value"+JSON.stringify(success));
      if (success && comp.newOpsObj.country !== '' && comp.newOpsObj.phone !== '') {
          // console.log("all fields are validated!!")
          await comp.createOpsUser(comp.newOpsObj);
          await comp.initializeOpsUser();

        //  await comp.newOpsObj.firstName = comp.newOpsObj.lastName = comp.newOpsObj.email = comp.newOpsObj.phone = comp.newOpsObj.country = comp.newOpsObj.password =  '';
          // if (error == null){
          await comp.$emit('update:is-add-new-user-sidebar-active', false)
          comp.$refs.registerForm.reset()
        // }
      }

    },
    phoneUpdate(event) {
      this.newOpsObj.phone = event.formattedNumber;
    },
    initializeOpsUser(){
      const comp = this
      comp.newOpsObj.firstName = "";
      comp.newOpsObj.lastName = "";
      comp.newOpsObj.email = "";
      comp.newOpsObj.phone = "";
      comp.newOpsObj.country = "";
      comp.newOpsObj.password  = "";
    }
  },
  mounted(){
    this.initializeOpsUser(query)
  },
}

</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
